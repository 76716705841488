body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
  background: white;
}

.app-container-auth {
  /* This fixed the error screen not working correctly on mobile */
  flex: 1;
}