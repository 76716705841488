@font-face {
  font-family: clarke-light;
  src: url("../assets/fonts/Clarke-Light.otf");
  src: url("../assets/fonts/Clarke-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Clarke-Regular.woff2") format("woff2"), url("../assets/fonts/Clarke-Regular.woff") format("woff"), url("../assets/fonts/Clarke-Regular.ttf") format("truetype"), url("../assets/fonts/Clarke-Regular.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: clarke-black;
  src: url("../assets/fonts/Clarke-Black.otf");
  src: url("../assets/fonts/Clarke-Black.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Clarke-Black.woff2") format("woff2"), url("../assets/fonts/Clarke-Black.woff") format("woff"), url("../assets/fonts/Clarke-Black.ttf") format("truetype"), url("../assets/fonts/Clarke-Black.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: clarke-regular;
  src: url("../assets/fonts/Clarke-Regular.otf");
  src: url("../assets/fonts/Clarke-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Clarke-Regular.woff2") format("woff2"), url("../assets/fonts/Clarke-Regular.woff") format("woff"), url("../assets/fonts/Clarke-Regular.ttf") format("truetype"), url("../assets/fonts/Clarke-Regular.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: d-din-condensed;
  src: url("../assets/fonts/D-DINCondensed.otf");
  src: url("../assets/fonts/D-DINCondensed.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/D-DINCondensed.woff2") format("woff2"), url("../assets/fonts/D-DINCondensed.woff") format("woff"), url("../assets/fonts/D-DINCondensed.ttf") format("truetype"), url("../assets/fonts/D-DINCondensed.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: d-din-condensed-bold;
  src: url("../assets/fonts/D-DINCondensed-Bold.otf");
  src: url("../assets/fonts/D-DINCondensed-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/D-DINCondensed-Bold.woff2") format("woff2"), url("../assets/fonts/D-DINCondensed-Bold.woff") format("woff"), url("../assets/fonts/D-DINCondensed-Bold.ttf") format("truetype"), url("../assets/fonts/D-DINCondensed-Bold.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: d-din-bold;
  src: url("../assets/fonts/D-DIN-Bold.otf");
  src: url("../assets/fonts/D-DIN-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/D-DIN-Bold.woff2") format("woff2"), url("../assets/fonts/D-DIN-Bold.woff") format("woff"), url("../assets/fonts/D-DIN-Bold.ttf") format("truetype"), url("../assets/fonts/D-DIN-Bold.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: d-din;
  src: url("../assets/fonts/D-DIN.otf");
  src: url("../assets/fonts/D-DIN.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/D-DIN.woff2") format("woff2"), url("../assets/fonts/D-DIN.woff") format("woff"), url("../assets/fonts/D-DIN.ttf") format("truetype"), url("../assets/fonts/D-DIN.svg#Clarke-Regular") format("svg"); }

@font-face {
  font-family: betfair-icons;
  src: url("../assets/fonts/betfair-icons.otf");
  src: url("../assets/fonts/betfair-icons.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/betfair-icons.woff2") format("woff2"), url("../assets/fonts/betfair-icons.woff") format("woff"), url("../assets/fonts/betfair-icons.ttf") format("truetype"), url("../assets/fonts/betfair-icons.svg#Clarke-Regular") format("svg"); }

.button-font, .button, .button--selected, .button-sport--selected, .button-event-status--selected, .button-location--selected, .region-dropdown--selected, .button-login, .button-signup, .button-sport, .button-event-status, .button-location, .region-dropdown {
  font-family: clarke-regular;
  font-size: 18px; }

.header-link-font {
  font-family: clarke-regular;
  font-size: 24px; }

.sidebar-header-font {
  font-family: clarke-regular;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

.customer-details-font {
  font-family: d-din-condensed;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal; }

.sidebar-race-item-font, .race-container .race-content .race-item {
  font-family: d-din;
  font-size: 14px; }

.sidebar-race-item-font--selected {
  font-family: d-din-bold;
  font-size: 14px; }

@media only screen and (max-width: 360px) {
  .header-link-font {
    font-size: 22px; } }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'betfair-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-register .path1:before {
  content: "\e900";
  color: black;
  opacity: 0.6; }

.icon-register .path2:before {
  content: "\e901";
  margin-left: -1.0625em;
  color: #1e1e1e; }

.icon-new-window:before {
  font-family: betfair-icons;
  content: "\e902"; }

.icon-logo-betfair:before {
  content: "\e903"; }

.icon-logo-arrows:before {
  content: "\e904"; }

.icon-close-thin:before {
  content: "\e905"; }

.icon-search:before {
  content: "\e906"; }

.icon-harness:before {
  content: "\e907"; }

.icon-greyhounds:before {
  content: "\e908"; }

.icon-horse-racing:before {
  content: "\e909"; }

.icon-chevron-down:before, .runners-header .header-icon--expand:before {
  font-family: 'betfair-icons';
  content: "\e90a"; }

.icon-chevron-up:before, .runners-header .header-icon:before {
  font-family: 'betfair-icons';
  content: "\e90b"; }

.icon-chevron-right:before {
  font-family: betfair-icons;
  content: "\e90c"; }

.icon-chevron-left:before {
  font-family: betfair-icons;
  content: "\e90d"; }

.icon-time:before {
  font-family: betfair-icons;
  content: "\e90e"; }

.icon-filter:before {
  font-family: betfair-icons;
  content: "\e90f"; }

.icon-login:before {
  font-family: betfair-icons;
  content: "\e910"; }

.icon-notification:before {
  font-family: betfair-icons;
  content: "\e911"; }

.icon-youtube:before, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-youtube:before {
  font-family: betfair-icons;
  content: "\e912"; }

.icon-facebook:before, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-facebook:before {
  font-family: betfair-icons;
  content: "\e913"; }

.icon-twitter:before, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-twitter:before {
  font-family: betfair-icons;
  content: "\e914"; }

.icon-google-plus:before {
  font-family: betfair-icons;
  content: "\e915"; }

.icon-in-play:before, .jump-in-play:before {
  font-family: betfair-icons;
  content: "\e916"; }

.header-0 {
  margin-top: 0 !important; }

.margin-5 {
  margin: 5px; }

.margin-10 {
  margin: 10px; }

.margin-top-5 {
  margin-top: 5px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-bottom-5 {
  margin-bottom: 5px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.color-black {
  color: #1e1e1e; }

.padding-5 {
  padding: 5px; }

.padding-right-5 {
  padding-right: 5px; }

.padding-right-10 {
  padding-right: 10px; }

.display-flex {
  display: flex; }

.flex-1 {
  flex: 1; }

.flex-2 {
  flex: 2; }

.flex-3 {
  flex: 3; }

.flex-4 {
  flex: 4; }

.display-none {
  display: none; }

.display-block {
  display: block; }

.text-align-right {
  text-align: right; }

.text-decoration-none {
  text-decoration: none; }

* > .row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  align-items: center; }
  * > .row:last-child {
    margin-bottom: 0em; }
  * > .row .right {
    margin-left: auto; }

@media only screen and (orientation: landscape) {
  body {
    -webkit-text-size-adjust: none; } }

.jump-normal {
  color: #1e1e1e; }

.jump-value {
  font-family: d-din-bold; }

.jump-time-ok {
  color: #116811; }

.jump-time-warning {
  color: #d86436; }

.jump-time-alert {
  color: #ce1313; }

.jump-in-play {
  color: #18773c; }

.jump-suspended {
  color: #ce1313; }

.jump-closed {
  color: #1e1e1e; }

.pill {
  border-radius: 500px;
  background-color: #595959;
  color: #ffffff;
  font-weight: bold; }
  .pill > div {
    width: 100%; }

.shadowed {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1); }

.spaced {
  display: flex;
  justify-content: space-between; }

.runner-dropdown {
  border-radius: 50%;
  flex-shrink: 0; }
  .runner-dropdown .rt__control {
    flex: inherit;
    justify-content: center;
    min-height: initial;
    background-color: grey;
    border-radius: 20px;
    border: none;
    padding: 0 8px 0 8px;
    cursor: pointer; }
  .runner-dropdown .rt__control--is-focused {
    box-shadow: none;
    background-color: #686868; }
  .runner-dropdown .rt__indicator {
    padding: 0; }
    .runner-dropdown .rt__indicator svg {
      fill: white;
      width: 16px;
      height: 16px; }
  .runner-dropdown .rt__indicator-separator {
    display: none; }
  .runner-dropdown .rt__value-container {
    flex: none;
    padding: 0; }
    .runner-dropdown .rt__value-container input {
      height: initial; }
  .runner-dropdown .rt__single-value {
    color: #333333;
    margin: 0;
    max-width: none;
    overflow: none;
    position: initial;
    text-overflow: initial;
    white-space: initial;
    top: initial;
    transform: none;
    color: white;
    font-weight: bold;
    font-size: 14px; }
  .runner-dropdown .rt__menu {
    width: 170%;
    font-size: 14px;
    z-index: 5; }
    .runner-dropdown .rt__menu .rt__menu-list {
      max-height: none;
      overflow-y: initial; }
      .runner-dropdown .rt__menu .rt__menu-list .rt__option {
        padding: 7px;
        text-align: center;
        color: lightgray;
        cursor: pointer; }
      .runner-dropdown .rt__menu .rt__menu-list .rt__option--is-selected {
        background-color: initial;
        font-weight: bold;
        color: initial; }
      .runner-dropdown .rt__menu .rt__menu-list .rt__option--is-focused {
        background-color: #f7f7f7; }

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #1E1E1E;
  font-family: d-din;
  font-weight: bold;
  font-size: 18px; }
  @media (min-width: 829px) {
    .header {
      padding-left: 36px;
      padding-right: 36px; } }
  .header .header-logo {
    height: 22px;
    width: 150px; }
  .header .header-link-items {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center; }
    @media (min-width: 830px) {
      .header .header-link-items {
        margin-left: 80px; } }
    .header .header-link-items a {
      text-decoration: none;
      color: #ffffff;
      padding-left: 15px; }
      @media (max-width: 829px) {
        .header .header-link-items a {
          display: none; } }
  .header .header-logout-button {
    color: #ffb80f;
    padding-left: 30px; }

@media only screen and (max-width: 319px) {
  .header {
    min-width: 320px;
    padding-left: 0; } }

@media only screen and (max-width: 767px) {
  .footer-container {
    padding-top: 19px;
    padding-bottom: 10px; }
    .footer-container .footer-content-container {
      padding-bottom: 0px; }
      .footer-container .footer-content-container a {
        color: white; }
      .footer-container .footer-content-container a:hover {
        color: #dfdfdf; }
      .footer-container .footer-content-container .footer-texts {
        padding-left: 16px;
        padding-right: 16px;
        flex-wrap: wrap;
        font-size: 0.8em; }
        .footer-container .footer-content-container .footer-texts .footer-license-nt {
          margin-bottom: 0.5em; }
        .footer-container .footer-content-container .footer-texts .footer-license-sa {
          margin-bottom: 0.5em; }
        .footer-container .footer-content-container .footer-texts .footer-responsible-gambling {
          margin-bottom: 0.5em; }
        .footer-container .footer-content-container .footer-texts .footer-links {
          margin-bottom: 0.5em; } }

@media only screen and (min-width: 768px) {
  .footer-container {
    padding-top: 15px;
    padding-bottom: 15px; }
    .footer-container .footer-social-media {
      display: none; }
    .footer-container .footer-content-container {
      height: 100%; }
      .footer-container .footer-content-container .ssc-link {
        color: black;
        text-decoration: none; }
      .footer-container .footer-content-container .rg-link {
        text-decoration: none; }
      .footer-container .footer-content-container a,
      .footer-container .footer-content-container a:active,
      .footer-container .footer-content-container a:link {
        color: white; }
        .footer-container .footer-content-container a .ssc-link,
        .footer-container .footer-content-container a:active .ssc-link,
        .footer-container .footer-content-container a:link .ssc-link {
          color: black;
          text-decoration: none; }
      .footer-container .footer-content-container a:hover {
        color: #dfdfdf; }
      .footer-container .footer-content-container .footer-texts {
        padding-left: 60px;
        padding-right: 60px;
        justify-content: center; }
        .footer-container .footer-content-container .footer-texts .footer-license-nt {
          margin-bottom: 20px; }
        .footer-container .footer-content-container .footer-texts .footer-license-sa {
          margin-bottom: 20px; }
        .footer-container .footer-content-container .footer-texts .footer-responsible-gambling {
          margin-bottom: 20px; }
        .footer-container .footer-content-container .footer-texts .footer-links {
          margin-bottom: 20px; } }

.app-container-auth {
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 5%;
  position: absolute;
  width: 100%;
  height: calc(100vh - 220px); }
  .app-container-auth.no-authenticated .footer-container {
    height: 100%;
    max-height: 200px; }

.footer-container {
  width: 100%;
  background-color: #1e1e1e;
  border-top: 2px #ffb80f solid;
  font-family: d-din;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  display: flex;
  flex-wrap: wrap; }
  .footer-container .footer-content-container {
    background-color: #1e1e1e;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    .footer-container .footer-content-container .ssc-reg {
      color: white; }
    .footer-container .footer-content-container .ssc-regbox {
      display: block;
      padding: .75em .5em;
      background: white;
      font-size: 14px;
      color: black !important; }
      .footer-container .footer-content-container .ssc-regbox .ssc-link {
        color: black;
        text-decoration: none;
        padding: 0;
        margin: 0; }
    .footer-container .footer-content-container .footer-texts {
      background-color: #1e1e1e;
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .footer-container .footer-content-container .footer-texts .footer-license-nt {
        display: flex;
        flex-wrap: wrap; }
      .footer-container .footer-content-container .footer-texts .footer-license-sa {
        display: flex;
        flex-wrap: wrap;
        color: white; }
      .footer-container .footer-content-container .footer-texts .footer-responsible-gambling {
        display: flex;
        flex-wrap: wrap;
        color: white; }
      .footer-container .footer-content-container .footer-texts a:link,
      .footer-container .footer-content-container .footer-texts a:visited,
      .footer-container .footer-content-container .footer-texts a:active {
        text-decoration: none;
        color: #ffb80f; }
      .footer-container .footer-content-container .footer-texts a:hover {
        color: #dfdfdf; }
      .footer-container .footer-content-container .footer-texts .footer-links {
        display: flex;
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        color: #ffb80f; }
        .footer-container .footer-content-container .footer-texts .footer-links .link-item:after {
          content: "|";
          padding: 0 0.5rem; }
        .footer-container .footer-content-container .footer-texts .footer-links .link-item:last-child:after {
          content: ""; }
    .footer-container .footer-content-container .footer-social-media {
      align-self: flex-start;
      background-color: #1e1e1e;
      flex-direction: column;
      flex-wrap: wrap; }
      .footer-container .footer-content-container .footer-social-media .follow-us {
        color: #ffffff;
        margin-bottom: 10px; }
      .footer-container .footer-content-container .footer-social-media .social-media-images {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row; }
        .footer-container .footer-content-container .footer-social-media .social-media-images .link-image, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-twitter, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-facebook, .footer-container .footer-content-container .footer-social-media .social-media-images .link-image-youtube {
          border-radius: 2px;
          margin-right: 15px;
          background-color: #1e1e1e;
          color: white;
          font-size: 35px;
          display: flex;
          width: 30px;
          height: 30px;
          align-items: center;
          justify-content: center; }
    .footer-container .footer-content-container .ssc-lw {
      padding-right: 7px;
      display: inline-block; }
      .footer-container .footer-content-container .ssc-lw a {
        text-decoration: none;
        color: #ffb80f;
        padding-right: 7px;
        border-right: 1px solid #646262; }
    .footer-container .footer-content-container .ssc-lw.last a {
      border: 0;
      padding: 0; }

@media only screen and (max-width: 829px) {
  .app-container-auth.no-authenticated {
    padding-top: 100px; } }

@media only screen and (max-height: 600px) and (orientation: landscape) {
  .app-container-auth.no-authenticated {
    padding-top: 100px;
    height: calc(100vh - 60px); }
    .app-container-auth.no-authenticated .footer-container {
      max-height: 250px; } }

@media only screen and (max-width: 319px) {
  .app-container-auth {
    min-width: 340px; }
  .footer-container {
    min-width: 340px; } }

.event-filter-container {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 8px 16px;
  color: white; }
  @media (min-width: 830px) {
    .event-filter-container {
      justify-content: flex-start;
      padding: 16px 32px 8px 32px; } }
  .event-filter-container .sport-filter-container {
    border: 2px solid #595959;
    border-radius: 26px;
    height: 26px;
    overflow: hidden;
    background-color: #595959; }
  .event-filter-container .button-sport, .event-filter-container .button-sport--selected {
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 27px;
    width: 30px;
    color: white;
    background-color: #595959; }
    .event-filter-container .button-sport.selected, .event-filter-container .selected.button-sport--selected {
      background-color: #60BC96; }
    .event-filter-container .button-sport:first-child, .event-filter-container .button-sport--selected:first-child {
      padding-left: 12px; }
    .event-filter-container .button-sport:last-child, .event-filter-container .button-sport--selected:last-child {
      padding-right: 12px; }
  .event-filter-container .button-filter-modal {
    display: flex;
    align-items: center;
    border-radius: 30px;
    border: 2px solid #FFB80C;
    font-weight: bold;
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px;
    cursor: pointer; }
    @media (min-width: 830px) {
      .event-filter-container .button-filter-modal {
        margin-left: 16px; } }
    .event-filter-container .button-filter-modal:active {
      background-color: rgba(255, 255, 255, 0.15); }
    .event-filter-container .button-filter-modal:hover {
      background-color: rgba(255, 255, 255, 0.15); }

.day-button-container {
  display: flex;
  padding: 0px 8px;
  color: #eeeeee;
  text-transform: capitalize;
  border-top: 1px solid #dfdfdf; }
  @media (min-width: 829px) {
    .day-button-container {
      padding: 0px 24px; } }

.button-event-day {
  padding: 12px 0px;
  margin: 0 8px;
  font-size: 14px;
  cursor: pointer; }
  @media (min-width: 829px) {
    .button-event-day {
      margin: 0 16px; } }
  .button-event-day.selected {
    font-weight: bold;
    border-bottom: 2px solid #ffb80f; }

.sport-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px; }

.region-button-container {
  margin-bottom: 20px; }

.race-container {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 16px; }
  @media (min-width: 830px) {
    .race-container {
      margin-left: 32px;
      margin-right: 32px;
      min-height: 72px; } }
  @media (max-width: 829px) {
    .race-container {
      min-height: 57px; } }
  .race-container .race-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    transition: margin-left 1s;
    min-height: 72px; }
    @media (max-width: 829px) {
      .race-container .race-content {
        overflow: scroll;
        padding-left: 16px;
        min-height: 57px;
        scrollbar-width: none;
        /* Firefox */ }
        .race-container .race-content::-webkit-scrollbar {
          /* WebKit */
          width: 0px;
          height: 0px;
          display: none; }
        .race-container .race-content::-webkit-scrollbar-thumb {
          /* WebKit */
          width: 0;
          height: 0;
          display: none; } }
    .race-container .race-content .race-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 4px;
      width: 100px;
      height: 45px;
      padding: 6px;
      margin-right: 6px;
      font-size: 12px;
      background-color: #333333;
      color: #eeeeee;
      cursor: pointer; }
      .race-container .race-content .race-item.selected {
        background-color: #595959; }
      @media (min-width: 830px) {
        .race-container .race-content .race-item {
          font-size: 14px;
          width: 140px;
          height: 60px;
          margin-right: 12px; } }
    .race-container .race-content .top-container {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .race-container .race-content .top-container .race-item-image {
        font-size: 16px; }
        @media (min-width: 830px) {
          .race-container .race-content .top-container .race-item-image {
            font-size: 20px; } }
      .race-container .race-content .top-container .race-item-time {
        font-size: 11px;
        font-weight: bold; }
      .race-container .race-content .top-container .jump-in-play {
        background-color: #18773c; }
      .race-container .race-content .top-container .jump-suspended {
        background-color: #D45959; }
      .race-container .race-content .top-container .jump-time-alert,
      .race-container .race-content .top-container .jump-time-warning,
      .race-container .race-content .top-container .jump-time-ok,
      .race-container .race-content .top-container .jump-time-suspended,
      .race-container .race-content .top-container .jump-in-play,
      .race-container .race-content .top-container .jump-closed,
      .race-container .race-content .top-container .jump-suspended {
        color: #eeeeee;
        padding: 1px 4px;
        border-radius: 6px; }
      .race-container .race-content .top-container .jump-time-alert {
        background-color: #D45959; }
    .race-container .race-content .race-item-name {
      font-weight: bold;
      padding-top: 4px;
      padding-bottom: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .race-container .race-zoom-shift {
    display: flex;
    flex-direction: column;
    min-height: 100%; }
    .race-container .race-zoom-shift .zoom-item {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 6px;
      background-color: tomato;
      color: white;
      background-color: #595959;
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer; }
      .race-container .race-zoom-shift .zoom-item.disabled {
        color: rgba(255, 255, 255, 0.2); }
      .race-container .race-zoom-shift .zoom-item:not(.disabled):active {
        color: rgba(255, 255, 255, 0.5); }

.sidebar-container {
  display: flex;
  flex-direction: column;
  background-color: #1E1E1E;
  border-top: solid 1px #dfdfdf; }
  @media only screen and (max-width: 829px) {
    .sidebar-container {
      width: 100%;
      max-width: initial; } }

@media only screen and (max-width: 767px) {
  .sidebar-container {
    width: 100%;
    max-width: 767px;
    flex-shrink: 1; } }

.button, .button--selected, .button-sport--selected, .button-event-status--selected, .button-location--selected, .region-dropdown--selected, .button-login, .button-signup, .button-sport, .button-event-status, .button-location, .region-dropdown {
  cursor: pointer;
  background-color: #1e1e1e;
  color: #ffb80f;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.button:hover, .button--selected:hover, .button-sport--selected:hover, .button-event-status--selected:hover, .button-location--selected:hover, .region-dropdown--selected:hover, .button-login:hover, .button-signup:hover, .button-sport:hover, .button-event-status:hover, .button-location:hover, .region-dropdown:hover {
  color: #ffffff; }

.button--selected, .button-sport--selected, .button-event-status--selected, .button-location--selected, .region-dropdown--selected, .button-login, .button-signup {
  background-color: #ffb80f;
  color: #1e1e1e; }

.button-event-status, .button-event-status--selected {
  height: 30px;
  flex: 1;
  margin: 2px;
  text-transform: uppercase; }

.button-location, .button-location--selected {
  height: 30px;
  flex: 1;
  margin: 2px; }

.region-button {
  height: 30px;
  flex: 2;
  margin: 2px; }

.region-dropdown {
  height: 30px; }

.button-login {
  flex: 1;
  border-style: none;
  white-space: nowrap; }

.button-signup {
  white-space: nowrap;
  flex: 1;
  border-style: none; }

.form-group {
  display: block;
  margin: 10px 0; }

.form-element-heading {
  display: inline-block;
  margin-bottom: 4px; }

.betfair-flex-container {
  display: flex;
  align-content: center;
  align-items: center; }

input {
  display: inline-block;
  border: none;
  border-radius: 6px;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 10px; }

.clickable, .header .header-logout-button {
  cursor: pointer; }

.custom-input, .radio-button, .checkbox-button {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer; }
  .custom-input.active, .active.radio-button, .active.checkbox-button {
    background-color: #60BC96; }
  .custom-input:active, .radio-button:active, .checkbox-button:active {
    background-color: #368061; }
  @media (max-width: 829px) {
    .custom-input, .radio-button, .checkbox-button {
      width: 20px;
      height: 20px; } }

.radio-button {
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: 0 0 0 1px lightgray;
  background-color: white;
  margin-left: 30px; }
  @media (max-width: 829px) {
    .radio-button {
      border: 2px solid white;
      box-shadow: 0 0 0 1px lightgray; }
      .radio-button.active {
        box-shadow: 0 0 0 2px #60BC96; } }
  @media (min-width: 830px) {
    .radio-button.active {
      box-shadow: 0 0 0 1px #60BC96; } }

.checkbox-button {
  border-radius: 3px;
  border: 1px solid lightgray;
  margin-left: 30px;
  margin-right: 16px; }
  .checkbox-button.active {
    border: 1px solid #60BC96; }
  .checkbox-button:after {
    content: '\2713';
    display: flex;
    color: white;
    justify-content: center;
    align-items: center; }

.auth-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  font-family: d-din;
  color: #595858; }
  .auth-dialog .input-text {
    width: 97%; }
  .auth-dialog header {
    text-transform: uppercase;
    color: #ffb80f;
    display: flex;
    font-weight: bold;
    margin: 19px 0;
    font-size: 27px;
    font-family: D-DIN;
    padding-bottom: 7px;
    border-bottom: 1px #1e1e1e solid; }
  .auth-dialog .text-small {
    font-family: d-din;
    font-size: 14px; }
  .auth-dialog .small-heading {
    width: 330px; }

.auth-dialog-loading {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%); }

.auth-dialog-additional-loading {
  background-color: #1e1e1e; }

.auth-dialog-small {
  background: #efefef;
  width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  padding: 20px;
  padding-top: 5px;
  box-sizing: border-box;
  z-index: 3; }
  @media screen and (min-height: 640px) {
    .auth-dialog-small {
      top: 35%; } }
  @media screen and (min-height: 500px) and (max-height: 640px) {
    .auth-dialog-small {
      top: 40%; } }

.forgotten-acct {
  flex: 4;
  text-align: end; }

.regs-container {
  border-top: 1px solid #1e1e1e;
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
  font-size: 10px; }
  .regs-container a:link,
  .regs-container a:visited,
  .regs-container a:active {
    text-decoration: none;
    color: #1e1e1e; }
  .regs-container a:hover {
    color: #ffb80f; }
  .regs-container .footer-license-nt {
    margin-bottom: 10px; }
  .regs-container .footer-license-sa {
    margin-bottom: 10px; }
  .regs-container .footer-responsible-gambling {
    margin-bottom: 10px; }

.footer-links {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  color: #1e1e1e; }
  .footer-links .link-item:after {
    content: "|";
    padding: 0 0.5rem; }
  .footer-links .link-item:last-child:after {
    content: ""; }

@media only screen and (max-width: 829px) {
  .auth-dialog-small {
    width: 95%;
    margin-bottom: 40px; } }

.event-info-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #1e1e1e;
  font-size: 1em;
  vertical-align: middle; }
  .event-info-content .event-details {
    display: flex;
    flex-direction: column; }
  .event-info-content .heading {
    font-family: d-din;
    font-size: 1.5em;
    margin-left: 0.5em; }
  .event-info-content .matched span {
    color: #60BC96;
    font-weight: bold;
    margin-left: 5px; }
  .event-info-content .time-to-jump {
    font-weight: bold; }
  .event-info-content .event-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.hidden {
  display: none; }

@media only screen and (max-width: 829px) {
  .hidden-mobile {
    display: none; } }

.none-opa {
  opacity: 0; }

.error-wrapper {
  display: flex;
  justify-content: center;
  align-content: center; }

.table-scroll {
  overflow-x: auto; }

table {
  border-spacing: 0px;
  border: solid 1px #d7d7d7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  table-layout: inherit; }
  table tr, table td {
    height: 45px; }
  table .runner-cell, table .runner-cell-container {
    display: flex;
    flex: 8;
    flex-direction: row;
    text-align: left;
    align-items: stretch;
    height: 100%; }
    table .runner-cell .flex-middle, table .runner-cell-container .flex-middle {
      display: flex;
      align-items: center;
      justify-content: center; }
    table .runner-cell .stall, table .runner-cell-container .stall {
      width: 1.5em;
      min-width: 1.5em;
      background-color: #1e1e1e;
      color: #ffffff;
      font-weight: bold; }
    table .runner-cell .silk, table .runner-cell-container .silk {
      width: 40px; }
    @media only screen and (max-width: 829px) {
      table .runner-cell .runner, table .runner-cell-container .runner {
        max-width: 13em;
        text-overflow: ellipsis;
        white-space: normal; } }
    table .runner-cell .runner-name, table .runner-cell-container .runner-name {
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden; }
    table .runner-cell .runner-jockey, table .runner-cell-container .runner-jockey {
      color: #BABABA; }
  table .rt-mini-graph-container.small {
    width: 40px;
    height: 2em;
    border: solid 1px #efefef;
    border-radius: 0.25em; }
  table .rt-mini-graph-container.large {
    max-width: 200px;
    height: 100%; }
    table .rt-mini-graph-container.large .VictoryContainer {
      height: 100%;
      max-height: 100%; }
  table .graph {
    margin-left: auto; }

.market-IN-PLAY td:not(.runner-info-cell-container), .market-IN-PLAY .runner-info-cell-container .runner-cell,
.market-SUSPENDED td:not(.runner-info-cell-container),
.market-SUSPENDED .runner-info-cell-container .runner-cell {
  opacity: 0.5;
  user-select: none;
  pointer-events: none; }

table th {
  font-weight: normal;
  overflow-wrap: normal;
  padding: 0; }

tbody td {
  border-top: solid 1.5px white;
  padding: 0; }
  tbody td:not(.no-border) {
    border: solid 1px #efefef;
    border-radius: 0.25em; }
  @media (max-width: 829px) {
    tbody td {
      min-width: inherit; } }
  @media (min-width: 830px) and (max-width: 1599px) {
    tbody td {
      min-width: 3.5em; } }
  @media (min-width: 1500px) and (max-width: 1650px) {
    tbody td {
      min-width: 60px; } }
  @media (min-width: 1651px) {
    tbody td {
      min-width: 70px; } }

.marketContainer-body {
  padding: 0;
  width: 100%;
  font-family: d-din;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal; }

.rt-header {
  text-align: center;
  background-color: black;
  font-family: d-din;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: white;
  /*padding: 15px 15px;*/ }

.rt-header-title {
  padding: 6px; }

.rt-header-title-left {
  padding-left: 30px;
  float: left;
  min-width: 60px; }

.rt-header-title-text {
  float: left; }

.back-header {
  background-color: #a6d8ff;
  color: black;
  font-weight: bold;
  min-width: 4em; }
  .back-header div {
    margin: 0.25em;
    line-height: 1.25em; }

.lay-header {
  background-color: #fac9d4;
  color: black;
  font-weight: bold;
  min-width: 4em; }
  .lay-header div {
    margin: 0.25em;
    line-height: 1.25em; }

.rt-body {
  font-family: D-DIN;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000; }

.rt-text-bold {
  font-weight: bold; }

.rt-blue {
  background-color: #a6d8ff; }

.rt-small-font {
  font-size: 10px !important; }

.rt-pink {
  background-color: #fac9d4; }

.rt-two-rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  /*  width: 60px;*/
  /*text-align: center;*/ }

.rt-two-rows-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 4em;
  margin: auto;
  /*text-align: center;*/ }

.rt-one-row-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 4em;
  margin: auto; }

.rt-two-rows-item {
  flex: 1;
  padding: 2px; }

.rt-miniGraph-td {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px; }

.rt-runner-scratched-cell {
  text-align: left; }

.rt-winner {
  flex: 1;
  text-align: center;
  align-self: center;
  font-weight: bold; }

.rt-winner-poly {
  clip-path: polygon(0% 0%, 100% 0, 77% 48%, 100% 100%, 0% 100%);
  background-color: black;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  width: 50px;
  height: 30px;
  display: flex; }

.rt-winner-poly-mobi {
  clip-path: polygon(0% 0%, 100% 0, 77% 48%, 100% 100%, 0% 100%);
  background-color: black;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 3px;
  width: 40px;
  height: 25px;
  display: flex; }

.winningPosition {
  align-self: center;
  padding-left: 10px; }

.runner-barrier-number {
  flex: 1;
  padding: 2px;
  width: 23px; }

.runner-icon {
  flex: 1;
  padding: 2px; }

.runner-icon-image {
  height: 21px; }

.runner-title {
  flex: 1;
  padding: 2px; }

.rt-dropdown__value-container {
  justify-content: flex-end;
  flex: 1; }

.rt-dropdown__control {
  background-color: black !important;
  border: none !important;
  border: none !important;
  box-shadow: none !important;
  flex-direction: row !important;
  min-width: 100px;
  cursor: pointer !important; }

.rt-dropdown__single-value {
  color: white !important; }

.rt-dropdown__indicator-separator {
  display: none !important; }

.rt-dropdown__menu {
  background-color: black !important; }

.rt-dropdown__indicator {
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important; }

.rt-dropdown__indicators {
  flex: 1; }

.rt-dropdown__option {
  cursor: pointer !important; }

.rt-dropdown__option--is-selected {
  background-color: black !important; }

.rt-dropdown__option--is-focused {
  background-color: #ffb80f !important; }

.rt-exchangeDeepLink {
  text-decoration: none;
  color: black; }

.rt-exchangeDeepLink-disabled {
  pointer-events: none; }

.rt-toolTip-grey {
  color: black !important;
  background-color: #dfdfdf !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
  opacity: 1 !important;
  text-align: left !important; }
  .rt-toolTip-grey div {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .rt-toolTip-grey b {
    margin-right: 20px; }

.rt-toolTip-grey:after {
  border-top-color: #dfdfdf !important; }

.panel-shown {
  display: block;
  opacity: 1;
  transition: all 1s ease-in-out; }

.market-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  padding: 0; }
  .market-container .table-body {
    overflow-x: auto; }

.runners-control-container {
  margin-right: 10px; }
  .runners-control-container .item-container {
    position: absolute;
    z-index: 3;
    overflow: hidden;
    background: #ffffff;
    border: solid 1px #efefef;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }
    .runners-control-container .item-container .row-item {
      padding: 0.25em 0.25em 0.25em 0.5em;
      cursor: pointer; }
    .runners-control-container .item-container .row-item-selected {
      background-color: #60BC96;
      color: #ffffff; }
    .runners-control-container .item-container .row-item-scratched {
      background-color: #efefef;
      color: #dfdfdf;
      cursor: initial; }
  .runners-control-container .header-text {
    padding: 0.5em; }

.runners-header {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  cursor: pointer; }
  .runners-header .header-icon {
    align-self: center;
    padding-top: 5px;
    margin-right: 5px; }
  .runners-header .header-icon--expand {
    align-self: center;
    padding-top: 5px;
    margin-right: 5px; }

.content {
  cursor: pointer;
  display: flex;
  flex-direction: column; }

.runner-table-sort-icon {
  cursor: pointer; }

.chart-element-positive {
  color: #84c164; }

.chart-element-negatve {
  color: #d7514f; }

.graph-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: clarke-regular;
  font-size: 24px;
  color: black; }

.popout-button {
  align-items: center;
  color: #60BC96;
  padding: 0.5em;
  cursor: pointer; }

.graph-vis {
  min-height: 20em; }
  @media (min-width: 830px) {
    .graph-vis {
      padding: 0 40px 40px 40px; } }
  .graph-vis svg {
    overflow: inherit; }

.trade-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.graph-header-container, .graph-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: clarke-regular;
  font-size: 18px;
  padding: 1em; }
  .graph-header-container .left-container, .graph-footer-container .left-container {
    display: flex;
    align-items: center; }
    .graph-header-container .left-container > div, .graph-footer-container .left-container > div {
      margin: 0.5em; }
  .graph-header-container .right-container, .graph-footer-container .right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .graph-header-container .right-container > div, .graph-footer-container .right-container > div {
      width: 10em; }
    .graph-header-container .right-container .item-container, .graph-footer-container .right-container .item-container {
      width: 10em; }
  .graph-header-container .pill, .graph-footer-container .pill {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #1e1e1e;
    border: solid #efefef 1px;
    width: 5em;
    height: 2em;
    text-align: center;
    cursor: pointer; }
    .graph-header-container .pill.selected, .graph-footer-container .pill.selected {
      background: #60BC96;
      color: #ffffff; }
    .graph-header-container .pill.disabled, .graph-footer-container .pill.disabled {
      background: #efefef;
      color: #dfdfdf; }
  .graph-header-container .graph-button--selected, .graph-footer-container .graph-button--selected {
    background-color: #60BC96; }
  .graph-header-container .mobile-runner-select-left, .graph-footer-container .mobile-runner-select-left {
    flex: 1;
    text-align: left; }
  .graph-header-container .mobile-runner-select-right, .graph-footer-container .mobile-runner-select-right {
    flex: 1;
    text-align: right; }
  .graph-header-container .mobile-runner-select-name, .graph-footer-container .mobile-runner-select-name {
    flex: 3;
    text-align: center; }

@media only screen and (max-width: 829px) {
  .no-races {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .no-races .no-races-text {
      font-family: clarke-regular; }
      .no-races .no-races-text .no-races-main-text {
        font-size: 36px; }
      .no-races .no-races-text .no-races-body-text {
        font-size: 18px; }
    .no-races .no-races-image {
      width: 257px;
      height: 257px;
      background-size: contain;
      background-image: url("../assets/images/no-races.png");
      background-repeat: no-repeat; } }

@media only screen and (min-width: 830px) {
  .no-races {
    padding: 40px;
    flex-direction: column;
    display: flex; }
    .no-races .no-races-text {
      font-family: clarke-regular; }
      .no-races .no-races-text .no-races-main-text {
        font-size: 48px; }
      .no-races .no-races-text .no-races-body-text {
        font-size: 20px; }
    .no-races .no-races-image {
      max-width: 514px;
      height: 514px;
      background-size: contain;
      background-image: url("../assets/images/no-races.png");
      background-repeat: no-repeat; } }

.error-handler {
  display: flex;
  justify-content: center;
  align-self: center;
  color: #ce1313;
  font-family: d-din-bold;
  padding: 5px;
  width: 50%;
  font-size: 16px;
  text-align: center; }

.main-container {
  width: 100%;
  overflow-x: auto;
  background-color: #ffffff; }
  @media (max-width: 829px) {
    .main-container {
      overflow-x: visible; } }
  .main-container .main-inner-container {
    margin: 0.5em;
    border-radius: 5px;
    background-color: #ffffff;
    overflow-x: visible;
    -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1); }
    @media (min-width: 830px) {
      .main-container .main-inner-container {
        margin: 36px;
        border-radius: 8px;
        -webkit-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.25); } }

.event-info-container {
  padding: 1em; }

.config-loading {
  color: #ffb80f; }

.config-heading {
  color: #1e1e1e; }

.config-body {
  color: #1e1e1e;
  margin-left: 50px;
  margin-top: 50px; }

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.25s linear, opacity 0.25s linear;
  -webkit-transition: visibility 0.25s linear, opacity 0.25s linear; }
  .modal-background.modal-closed {
    visibility: hidden;
    opacity: 0; }
  .modal-background.fullscreen {
    background: rgba(25, 25, 25, 0.95); }
    .modal-background.fullscreen .modal {
      background-color: initial; }
  .modal-background:after {
    font-family: betfair-icons;
    content: "\e905";
    color: white;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    font-size: 25px; }

.modal {
  width: 100%;
  border-radius: 4px;
  background-color: white;
  color: #292929;
  position: relative;
  -webkit-box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1); }
  @media (min-width: 830px) {
    .modal {
      font-size: 14px; } }

.modal-slide-up {
  transition: bottom 0.25s;
  -webkit-transition: bottom 0.25s;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute; }
  .modal-slide-up.modal-slide-hidden {
    bottom: -100%;
    transition: bottom 0.25s;
    -webkit-transition: bottom 0.25s; }

.positional-modal-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3; }
  .positional-modal-background.modal-closed {
    display: none; }

.positional-modal {
  position: absolute; }

.modal-heading {
  border-bottom: 1px solid #E0E0E0;
  text-align: center;
  padding: 16px 0 8px;
  font-weight: bold; }

.modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 8px;
  box-sizing: border-box; }
  @media (max-width: 829px) {
    .modal-body {
      flex-direction: column; } }

@media (min-width: 830px) {
  .modal-checkbox-container {
    border-left: 1px solid #E0E0E0;
    padding-left: 15px;
    margin-left: 15px; } }

.modal-section {
  padding: 16px 8px 8px 8px;
  border-bottom: 1px solid #E0E0E0; }
  @media (min-width: 830px) {
    .modal-section {
      border-bottom: none; } }
  .modal-section .modal-section-heading {
    font-weight: bold;
    padding-bottom: 5px; }
  .modal-section .modal-section-list.checkbox-grid {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 830px) {
      .modal-section .modal-section-list.checkbox-grid {
        width: 200px; } }
  .modal-section .modal-section-list .modal-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #707070; }
    .modal-section .modal-section-list .modal-list-item input {
      margin: 0 0 0 16px;
      padding: 0;
      width: 25px; }
      @media (min-width: 830px) {
        .modal-section .modal-section-list .modal-list-item input {
          width: 18px; } }

.modal-triangle-notch {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
  z-index: 1; }

.modal-button-submit {
  margin: 12px;
  border-radius: 4px;
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
  font-weight: bold;
  background-color: #60BC96;
  color: white; }

.modal-rotate-phone {
  width: 100vw;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: d-din;
  font-size: 18px; }
  .modal-rotate-phone #phone-image {
    height: 200px; }

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  float: left;
  margin-right: 5px;
  background: #60BC96;
  -webkit-animation: bounce 1s infinite;
  animation: bounce 1s infinite; }

.dot-0 {
  -webkit-animation-delay: .1s;
  animation-delay: .1s; }

.dot-1 {
  -webkit-animation-delay: .3s;
  animation-delay: .3s; }

.dot-2 {
  -webkit-animation-delay: .5s;
  animation-delay: .5s; }

@-webkit-keyframes bounce {
  0%, 100% {
    opacity: 1; }
  60% {
    opacity: .0; } }

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #efefef;
  height: 100%; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input {
    font-size: 16px; } }

html, body, #root {
  height: 100%; }

body {
  min-height: 640px; }
